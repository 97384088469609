import { ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from 'react-ga4';

/** Establishing types for prop management with our component */
interface paramObject {
    [key: number]: string;
  }
interface StepFormProps {
    index: number;
    children?: ReactNode;
    callback: Function;
    urlParams: paramObject;
}

let originalURLParam1: string 

/** Start our Step Form component 
 * ------
 * Props:
 * ------
 * index - The step the user is currently on passed from parent state
 * callback - function passed from parent to change parent state
 * urlParams - passing this state object from the parent to handle
 * some conditional displays and information rendering
 * ------
 * Functions:
 * ------
 * callbackOnClick - controls the onclick for this component to use the
 * callback passed in props
 * buildAndRedirect - the final function to take the entire urlParams and
 * build the proper link to the Qure4u form
 * stepContentRender - extremely large switch case to build content for each
 * step (TODO: make this cleaner...)
 * ------
 * Vars:
 * ------
 * list & item - animation variant handling (used like this to do sequential
 * animations)
 */
function StepForm({index, children, callback, urlParams}:StepFormProps) {

    /** Many steps of the form require a callback to change parent state
     * callback is passed via props
     * this prevents unnecessary use of a global state (context or redux)
     * for such a small app
     */
    function callbackOnClick(res: string){
        callback(res);
        console.log(urlParams);
        //store the original url param 1 if the user selects Behavioral Health
        if (urlParams[1] === "Behavioral Health") {
            originalURLParam1 = urlParams[1];
        }

        // If the user selects "I am NOT an established patient at CHAS Health" in case 1
        if (urlParams[2] === "New" && urlParams[1] === "Behavioral Health") {
            // Update urlParams accordingly
            urlParams[1] = "Behavioral Health";
            console.log(urlParams);
        } 

        // If the user selects "I am NOT an established patient at CHAS Health" in case 1
        /*if (urlParams[2] !== "New" && urlParams[1] === "Medical" && originalURLParam1 === "Behavioral Health") {
            // Update urlParams accordingly
            urlParams[1] = "Behavioral Health";
        } */

    }

    /** This function is used to parse all selected options and build the redirect link */
    function buildAndRedirect(){
        let url = "https://chashealth.ourscheduling.com/369/doctors?";
        

        // Loop through all the patient selected parameters
        for(const prop in urlParams){
            

            if(urlParams[1] === "Urgent Care" && urlParams[3]==="Washington"){
                if(urlParams[4]==="Video")
                {
                    if(urlParams[5]==="Established")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=1";
                    }
                    else if(urlParams[5]==="New")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=13";
                    }  
                }
                if(urlParams[4]==="In Person")
                {
                    if(urlParams[5]==="Established")
                    {
                        if (urlParams[6]==="Spokane Urgent Care North")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=2";
                        }
                        else if (urlParams[6]==="Spokane Urgent Care Valley")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=2";
                        }
                    }
                    else if(urlParams[5]==="New")
                    {
                        if (urlParams[6]==="Spokane Urgent Care North")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=12";
                        }
                        else if (urlParams[6]==="Spokane Urgent Care Valley")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=12";
                        }
                    }
                }
                
            }
            else if(urlParams[1] === "Urgent Care" && urlParams[2]==="Medication Refills" && urlParams[4]==="No"){
                if(urlParams[3]==="Yes" && urlParams[6]==="video")
                {
                    url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=1";
                }
                else if(urlParams[3]==="No" && urlParams[6]==="video")
                {
                    url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=13";
                }
                else if(urlParams[3]==="Yes" && urlParams[6]==="In Person")
                {
                    if(urlParams[7]==="Spokane Urgent Care North")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=2";
                    }
                    else if (urlParams[7]==="Spokane Urgent Care Valley")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=2";
                    }
                }
                else if(urlParams[3]==="No" && urlParams[6]==="In Person")
                {
                    if(urlParams[7]==="Spokane Urgent Care North")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=12";
                    }
                    else if (urlParams[7]==="Spokane Urgent Care Valley")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=12";
                    }
                }
            }
            else if(urlParams[1] === "Urgent Care" && urlParams[2]==="Medication Refills"){
                if(urlParams[3]==="Yes" && urlParams[5]==="video")
                {
                    url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=1";
                }
                else if(urlParams[3]==="No" && urlParams[5]==="video")
                {
                    url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=13";
                }
                else if(urlParams[3]==="Yes" && urlParams[5]==="In Person")
                {
                    if(urlParams[6]==="Spokane Urgent Care North")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=2";
                    }
                    else if (urlParams[6]==="Spokane Urgent Care Valley")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=2";
                    }
                }
                else if(urlParams[3]==="No" && urlParams[5]==="In Person")
                {
                    if(urlParams[6]==="Spokane Urgent Care North")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=12";
                    }
                    else if (urlParams[6]==="Spokane Urgent Care Valley")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=12";
                    }
                }
            }
            
            else if(urlParams[1] === "Urgent Care" && urlParams[4]==="Washington"){
                if(urlParams[5]==="Video")
                {
                    if(urlParams[6]==="Established")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=1";
                    }
                    else if(urlParams[6]==="New")
                    {
                        url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=119995&stateId=48&visitTypeId=13";
                    }
                }
                else if (urlParams[5] === "In Person")
                {
                    if(urlParams[6]==="Established")
                    {
                        if (urlParams[7]==="Spokane Urgent Care North")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=2";
                        }
                        else if (urlParams[7]==="Spokane Urgent Care Valley")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=2";
                        }
                    }
                    else if(urlParams[6]==="New")
                    {
                        if (urlParams[7]==="Spokane Urgent Care North")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120006&stateId=48&visitTypeId=12";
                        }
                        else if (urlParams[7]==="Spokane Urgent Care Valley")
                        {
                            url = "https://chashealth.ourscheduling.com/369/doctors?&specialtyId=103&departmentId=120000&stateId=48&visitTypeId=12";
                        }
                    }
                }
                
            }
            else {
                if(prop === "1"){
                    //dentist
                    if(urlParams[prop] === "Dental"){
                        url = url.concat("&specialtyId=128");
                    }
                    if(urlParams[prop] === "Immunization"){
                        url = url.concat("&specialtyId=269&visitTypeId=5");
                    }
                    if(urlParams[prop] === "Insurance Enrollment"){
                        url = url.concat("&specialtyId=6");
                    }
                    if(urlParams[prop] === "Behavioral Health"){
                        url = url.concat("&specialtyId=7");
                    }
                    
                }
                if(prop === "3"){
                    //state, specialty and visit type
                    if(urlParams[prop] === "Primary Care"){
                        url = url.concat("&specialtyId=130&stateId=48&visitTypeId=2");
                    }
                    if(urlParams[prop] === "Behavorial Health"){
                        url = url.concat("&specialtyId=7&stateId=48&visitTypeId=2");
                    }
                    if(urlParams[prop] === "Immunization"){
                        url = url.concat("&specialtyId=269&stateId=48&visitTypeId=5");
                    }
                }
                if(prop === "4"){
                    //state
                    if(urlParams[prop] === "Washington"){
                        url = url.concat("&stateId=48");
                    }
                    if(urlParams[prop] === "Idaho"){
                        url = url.concat("&stateId=13");
                    }
                }
                
                if(prop === "5"){
                    //age
                    if(urlParams[prop] === "Child"){
                        if(urlParams[1] === "Medical"){
                            url = url.concat("&specialtyId=64");
                        }
                    }
                    if(urlParams[prop] === "Adult/Self"){
                        if(urlParams[1] === "Medical"){
                            url = url.concat("&specialtyId=130");
                        }
                    }
                }
                if(prop === "6" || (urlParams[1] === "Behavioral Health" && prop === "7")||(urlParams[1]==="School Based Health Clinics")){
                    //location
                    switch(urlParams[prop]){
                        case "Behavioral Health Center North":
                            url = url.concat("&departmentId=121883");
                            break;
                            //same as the Lewiston Behavioral Health Center
                            case "Behavioral Health Center Lewiston":
                            url = url.concat("&departmentId=119991");
                            break;
                        case "Cheney Dental":
                            url = url.concat("&departmentId=120089");
                            break;
                        case "Cheney Health Center":
                            url = url.concat("&departmentId=119937");
                            break;
                        case "Denny Murphy Clinic":
                            url = url.concat("&departmentId=119967");
                            break;
                        case "Denny Murphy Dental":
                            url = url.concat("&departmentId=120085");
                            break;

                        case "East Central Dental Clinic":
                            url = url.concat("&departmentId=121228");
                            break;
                        case "East Mission Dental":
                            url = url.concat("&departmentId=120091");
                            break;
                        case "Family Dental":
                            url = url.concat("&departmentId=120092");
                            break;
                        case "Latah Community Health":
                            url = url.concat("&departmentId=119992");
                            break;
                        case "Latah Dental":
                            url = url.concat("&departmentId=120090");
                            break;
                        case "Lewis and Clark Dental Clinic":
                            url = url.concat("&departmentId=120088");
                            break;
                        case "Lewiston Behavioral Health Center":
                            url = url.concat("&departmentId=119991");
                            break;
                        case "Lewis and Clark Health Center":
                            url = url.concat("&departmentId=119991");
                            break;
                        case "Maple Dental":
                            url = url.concat("&departmentId=120086");
                            break;
                        case "Maple Street Clinic":
                            url = url.concat("&departmentId=119968");
                            break;
                        case "Market Street Clinic":
                            url = url.concat("&departmentId=119875");
                            break;
                        case "North Central Clinic":
                            url = url.concat("&departmentId=119990");
                            break;
                        case "North Central High School":
                            url = url.concat("&departmentId=124987");
                            break;
                        case "North County Clinic":
                            url = url.concat("&departmentId=119582");
                            break;
                        case "North County Dental":
                            url = url.concat("&departmentId=120087");
                            break;
                        case "Perry Street Clinic":
                            url = url.concat("&departmentId=119784");
                            break;
                        case "Parkside Clinic":
                            url = url.concat("&departmentId=124988");
                            break;
                        case "Rogers":
                            url = url.concat("&departmentId=119983");
                            break;
                        case "Rogers High School":
                            url = url.concat("&departmentId=119983");
                            break;
                        case "Shiloh Hills Elementary School":
                            url = url.concat("&departmentId=124102");
                            break;
                        case "Shadle Park High School":
                            url = url.concat("&departmentId=124986");
                            break;
                        case "Southgate Clinic":
                            url = url.concat("&departmentId=119766");
                            break;
                        case "Valley Clinic":
                            url = url.concat("&departmentId=119928");
                            break;
                    }
                }
                if(prop === "7" || (urlParams[1] === "Behavioral Health" && prop === "8")){
                    //location
                    switch(urlParams[prop]){
                        case "In Person":
                            if(urlParams[2] === "New"){
                                if(urlParams[5] === "Child"){
                                    url = url.concat("&visitTypeId=2");
                                } else {
                                    url = url.concat("&visitTypeId=12");
                                }
                            } else {
                                url = url.concat("&visitTypeId=2");
                            }
                            break;
                        case "Virtual":
                            if(urlParams[2] === "New"){
                                url = url.concat("&visitTypeId=13");
                            } else {
                                url = url.concat("&visitTypeId=1");
                            }
                            break;
                        case "Vaccination":
                            url = url.concat("&visitTypeId=5");
                            break;
                    }
                }
            }
        }
        // ReactGA.event({
        //     category: 'self_scheduling',
        //     action: 'self_scheduling_complete'
        //   });
        //console.log(url);
        ReactGA.event({
            category: "self_scheduling",
            action: "self scheduling completed"
          });
        window.location.href = url;
    }
    function dentalRedirect(bool:boolean){
        if(bool){
            //dental washington
            window.location.href = "https://chashealth.ourscheduling.com/369/doctors?stateId=48&specialtyId=128&visitTypeId=2";
        } else {
            //dental idaho
            window.location.href = "https://chashealth.ourscheduling.com/369/doctors?stateId=13&specialtyId=128&visitTypeId=2";
        }
    }

    /** Animation Variants */
    const list = {
        visible: {
          opacity: 1,
          x: 0,
          y: 0,
          transition: {
            delay: 0.5,
            duration: 0.4,
            when: "beforeChildren",
            staggerChildren: 0.3,
          },
        },
        hidden: {
          opacity: 0,
          y: 0,
          x: 250,
          transition: {
            when: "afterChildren",
          },
        },
      }
      const item = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
      }

      function locationRender(type:string, state:string){
        console.log("Type is " + type + " and state is " + state + ".")
        if((type === "Medical") ){
            console.log("LOCATION RENDER: ", urlParams)
            if(state === "Washington" && urlParams[5]==="Adult/Self"){
                return (
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Cheney Health Center")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Cheney Medical</button>
                            <button onClick={()=>callbackOnClick("Denny Murphy Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Denny Murphy Medical</button>
                            <button onClick={()=>callbackOnClick("Maple Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Maple Medical</button>
                            <button onClick={()=>callbackOnClick("Market Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Market Medical</button>
                            <button onClick={()=>callbackOnClick("North Central Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North Central Medical</button>                           
                        </div>
                        <div className="flex justify-start items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("North County Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North County Medical</button>
                            <button onClick={()=>callbackOnClick("Perry Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Perry Medical</button>
                            {/* {urlParams[1] === "Medical" && urlParams[4] === "Washington" && (<button onClick={()=>callbackOnClick("Rogers")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Rogers Medical</button>)}  */}
                            <button onClick={()=>callbackOnClick("Parkside Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Parkside Medical</button>
                            <button onClick={()=>callbackOnClick("Southgate Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Southgate Medical</button>
                            <button onClick={()=>callbackOnClick("Valley Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Valley Medical</button>
                        </div>
                    </>   
                    )
            }
            if(state === "Washington" && urlParams[5]==="Child"){
                return (
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Cheney Health Center")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Cheney Medical</button>
                            <button onClick={()=>callbackOnClick("Denny Murphy Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Denny Murphy Medical</button>
                            <button onClick={()=>callbackOnClick("Maple Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Maple Medical</button>
                            <button onClick={()=>callbackOnClick("Market Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Market Medical</button>
                            <button onClick={()=>callbackOnClick("North Central Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North Central Medical</button>                           
                        </div>
                        <div className="flex justify-start items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("North County Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North County Medical</button>
                            <button onClick={()=>callbackOnClick("Perry Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Perry Medical</button>
                            {/* {urlParams[1] === "Medical" && urlParams[4] === "Washington" && (<button onClick={()=>callbackOnClick("Rogers")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Rogers Medical</button>)}  */}
                            <button onClick={()=>callbackOnClick("Southgate Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Southgate Medical</button>
                            <button onClick={()=>callbackOnClick("Valley Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Valley Medical</button>
                        </div>
                    </>   
                    )
            } else if (state ==="Idaho"){
                return(
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Lewis and Clark Health Center")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Lewis & Clark Medical</button>
                            <button onClick={()=>callbackOnClick("Latah Community Health")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Latah Medical</button>
                        </div>
                    </>
                )
            }        
        } else if(type === "Dental"){
            if(state === "Washington"){
                return(
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Cheney Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Cheney Dental</button>
                            <button onClick={()=>callbackOnClick("Denny Murphy Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Denny Murphy Dental</button>
                            <button onClick={()=>callbackOnClick("East Central Dental Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">East Central Dental</button>
                            <button onClick={()=>callbackOnClick("East Mission Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">East Mission Dental</button>
                        </div>
                        <div className="flex justify-start items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Family Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Family Dental</button>
                            <button onClick={()=>callbackOnClick("Maple Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Maple Dental</button>
                            <button onClick={()=>callbackOnClick("North County Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North County Dental</button>
                        </div>
                    </>
                )
            } else if (state ==="Idaho"){
                return(
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Latah Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Latah Dental</button>
                            <button onClick={()=>callbackOnClick("Lewis and Clark Dental Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Lewis & Clark Dental</button>
                        </div>
                    </>
                )
            }
            
        } 
        else if((type === "Immunization" || type ==="Insurance Enrollment") ){
            console.log("LOCATION RENDER: ", urlParams)
            if(state === "Washington"){
                return (
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Cheney Health Center")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Cheney Medical</button>
                            <button onClick={()=>callbackOnClick("Denny Murphy Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Denny Murphy Medical</button>
                            <button onClick={()=>callbackOnClick("Maple Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Maple Medical</button>
                            <button onClick={()=>callbackOnClick("Market Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Market Medical</button>
                            <button onClick={()=>callbackOnClick("North Central Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North Central Medical</button>                           
                        </div>
                        <div className="flex justify-start items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("North County Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North County Medical</button>
                            <button onClick={()=>callbackOnClick("Perry Street Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Perry Medical</button>
                            {/* {urlParams[1] === "Medical" && urlParams[4] === "Washington" && (<button onClick={()=>callbackOnClick("Rogers")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Rogers Medical</button>)}  */}
                            <button onClick={()=>callbackOnClick("Southgate Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Southgate Medical</button>
                            <button onClick={()=>callbackOnClick("Valley Clinic")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Valley Medical</button>
                        </div>
                    </>   
                    )
            } else if (state ==="Idaho"){
                return(
                    <>
                        <div className="flex justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Lewis and Clark Health Center")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Lewis & Clark Medical</button>
                            <button onClick={()=>callbackOnClick("Latah Community Health")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Latah Medical</button>
                        </div>
                    </>
                )
            }        
        }
        else if(type === "Behavioral Health" && urlParams[2] === "Established"){
            if(state === "Washington"){
                return (
                    <>
                        <div className="flex-1 justify-center items-center flex-col h-full">
                            {/* <button onClick={()=>callbackOnClick("Behavioral Health Center North")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Behavioral Health Center North</button> */}
                            <button onClick={()=>callbackOnClick("Cheney Health Center")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Cheney</button>
                            <button onClick={()=>callbackOnClick("Denny Murphy Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Denny Murphy</button>
                            <button onClick={()=>callbackOnClick("Maple Street Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Maple</button>
                            <button onClick={()=>callbackOnClick("Market Street Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Market</button>
                            <button onClick={()=>callbackOnClick("North Central Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North Central</button>                           
                        </div>
                        <div className="flex-1 justify-start items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("North County Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North County</button>
                            <button onClick={()=>callbackOnClick("Perry Street Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Perry</button>
                            {/* <button onClick={()=>callbackOnClick("Rogers")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Rogers</button>  */}
                            <button onClick={()=>callbackOnClick("Southgate Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Southgate</button>
                            <button onClick={()=>callbackOnClick("Valley Clinic")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Valley</button>
                        </div>
                    </>   
                )
            } else if (state ==="Idaho"){
                return(
                    <>
                        <div className="flex-1 justify-center items-center flex-col h-full">
                            {/* <button onClick={()=>callbackOnClick("Behavioral Health Center Lewiston")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Behavioral Health Center Lewiston</button> */}
                            <button onClick={()=>callbackOnClick("Lewis and Clark Health Center")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Lewis & Clark</button>
                            <button onClick={()=>callbackOnClick("Latah Community Health")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Latah</button>
                        </div>
                    </>
                )
            }
        }
        else if(urlParams[1] === "Urgent Care" && state === "In Person"){
            
            return (
                <>
                    <div className="flex-1 justify-center items-center flex-col h-full">
                        {/* <button onClick={()=>callbackOnClick("Behavioral Health Center North")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Behavioral Health Center North</button> */}
                        <button onClick={()=>callbackOnClick("Spokane Urgent Care North")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Spokane Urgent Care North</button>
                        <button onClick={()=>callbackOnClick("Spokane Urgent Care Valley")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Spokane Urgent Care Valley</button>                           
                    </div>
                </>   
            )
            
        } 
        else if(type === "Behavioral Health" && urlParams[2] === "New"){
            if(state === "Washington"){
                return (
                    <>
                        <div className="flex-1 justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Behavioral Health Center North")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Behavioral Health Center North</button>                        </div>
                    </>   
                    )
            } else if (state ==="Idaho"){
                return(
                    <>
                        <div className="flex-1 justify-center items-center flex-col h-full">
                            <button onClick={()=>callbackOnClick("Behavioral Health Center Lewiston")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Behavioral Health Center Lewiston</button>
                        </div>
                    </>
                )
            }
        }
        else if(type === "School Based Health Clinics"){
            
                return (
                    <>
                        <div className="flex-1 justify-center items-center flex-col h-full">
                        <button onClick={()=>callbackOnClick("North Central High School")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">North Central High School</button>
                        <button onClick={()=>callbackOnClick("Rogers High School")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Rogers High School</button>                        
                        <button onClick={()=>callbackOnClick("Shadle Park High School")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Shadle Park High School</button>
                        <button onClick={()=>callbackOnClick("Shiloh Hills Elementary School")} className="p-2 m-1  bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow-0 w-full">Shiloh Hills Elementary School</button>
                        
                        
                        </div>
                    </>   
                    )
            
        }
      }
    /** Large switch case method handling the actual content display in each step
     * This is pretty gnarly so we should consider potentially moving each
     * case into its own component?  That would make this look cleaner but
     * it would not optimize run time at all...
     */
    function stepContentRender(step: number) {
        switch(step) {
            case 0:
                return (
                    <motion.div className="flex justify-center items-center flex-col max-w-[800px]">
                       
                        <motion.p variants={item} className="font-bold mb-[10px]">I would like to schedule for:</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            <button onClick={()=>callbackOnClick("Medical")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Medical</button> 
                            <button onClick={()=>callbackOnClick("Dental")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Dental</button>
                            <button onClick={()=>callbackOnClick("Immunization")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Immunization</button>
                            <button onClick={()=>callbackOnClick("Behavioral Health")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Behavioral Health</button>
                            <button onClick={()=>callbackOnClick("Insurance Enrollment")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Insurance Enrollment</button>
                            <button onClick={()=>callbackOnClick("Urgent Care")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Urgent Care</button>
                            <button onClick={()=>callbackOnClick("School Based Health Clinics")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">School Based Health Clinics</button>
                            <button onClick={()=>callbackOnClick("Other")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Other</button>
                        </motion.div>
                        <motion.p variants={item} className='w-full mx-auto font-normal md:font-bold text-sm mb-4'>If this is an emergency, please do not use this tool and instead call 911.  If you have a question related to an urgent medical concern, please call our triage nurse team available 24 hours per day. <a className='text-chas-green' rel="noreferrer" target="_blank" href="https://chas.org/get-care/after-hours-care/">Learn more</a> or visit one of our <a className='text-chas-green' rel="noreferrer" target="_blank" href="https://chas.org/get-care/urgent-care/">Spokane Urgent Care Clinics</a>. For information on insurance, payment, and a good faith estimate for the cost of your visits, <a className='text-chas-green' rel="noreferrer" target="_blank" href="https://chas.org/for-patients/insurance-payment/good-faith-estimates/">click here</a>.</motion.p>
                    </motion.div>
                    );
            case 1:
                console.log('originalURLParam1:', originalURLParam1);
                /*if(urlParams[1] === "Urgent Care"){
                setTimeout(function(){
                    callbackOnClick("Continue");
                }, 500)
                
                return;
            }*/
                return <motion.div className="flex justify-center items-center flex-col">
                    {urlParams[1] === "Medical" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-xl sm:text-2xl font-bold mb-[20px]">Patient appointment self-scheduling is currently available for:</motion.p>
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">New and Established primary care patients for either an in-person or video visit.</motion.p> 
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">Please call us for any other appointment needs at this time. We are working to add additional appointment types to our self scheduling tool soon.</motion.p> 
                            <motion.p variants={item} className="font-bold mb-[10px]"><a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> <br /> <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a></motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("New")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">I have not been seen at CHAS Health in the past 3 years</button> 
                                <button onClick={()=>callbackOnClick("Established")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">I’ve been a patient at CHAS Health within the last 3 years</button>
                            </motion.div>
                        
                        </div>
                    )}
                    
                    {urlParams[1] === "Urgent Care" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-xl sm:text-2xl font-bold mb-[20px]">Please select what you would like to be seen for. </motion.p>
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">If your reason is not listed, please call 509.444.8200</motion.p>
                            
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("Cold")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Cold and Flu-like symptoms without shortness of breath or chest pain</button> 
                                <button onClick={()=>callbackOnClick("Allergy")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Seasonal Allergies</button>
                                <button onClick={()=>callbackOnClick("Sinusitis")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Sinusitis</button> 
                                <button onClick={()=>callbackOnClick("Pink Eye")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Pink Eye</button>
                                <button onClick={()=>callbackOnClick("Styes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Styes</button> 
                                <button onClick={()=>callbackOnClick("Canker")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Cold or Canker sores</button>
                                <button onClick={()=>callbackOnClick("UTI")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">UTI</button> 
                                <button onClick={()=>callbackOnClick("Rashes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Rashes</button>
                                <button onClick={()=>callbackOnClick("Poison Ivy")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Poison Ivy</button> 
                                <button onClick={()=>callbackOnClick("Diarrhea")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Diarrhea</button>
                                <button onClick={()=>callbackOnClick("Titer Checks")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Titer Checks</button> 
                                <button onClick={()=>callbackOnClick("TB Screening")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">TB Screening</button>
                                <button onClick={()=>callbackOnClick("Medication Refills")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Medication Refill</button> 
                                <button onClick={()=>callbackOnClick("Other")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Other</button> 

                                
                            
                            
                            </motion.div>
                        
                        </div>
                    )}
                    {urlParams[1] === "Dental" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                            <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of:</motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <button onClick={()=>dentalRedirect(true)} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                <button onClick={()=>dentalRedirect(false)} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Idaho</button>
                            </motion.div>
                        </div>
                    )}
                    {urlParams[1] === "Immunization" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Immunization appointment self scheduling is currently available for:</motion.p>
                            <motion.p variants={item} className="font-bold mb-[10px]">Established primary care medical patients.</motion.p>
                            <motion.p variants={item} className="font-bold mb-[10px]">Please Call us for any other appointment needs at this time. We are working to add additional appointment types to our self scheduling tool soon.</motion.p> 
                            <motion.p variants={item} className="font-bold mb-[10px]"><a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> <br /> <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a></motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("Established")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">I’ve been a patient at CHAS Health within the last 3 years</button>
                                <a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>
                            </motion.div>
                            
                        </div>
                    )}                    
                    {urlParams[1] === "Behavioral Health" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-xl sm:text-2xl font-bold mb-[20px]">Behavioral Health patient appointment self-scheduling is currently available for:</motion.p>
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">Established behavioral health patients for either an in-person or video visit.</motion.p> 
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">If you are experiencing a crisis or need immediate help, please contact <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:1.877.266.1818">1.877.266.1818</a> or <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:988">988</a></motion.p> 
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">If Substance Use Disorder or CHAS Opioid Recovery Clinic patient, please call us. We are working to add additional appointment types to our self scheduling tool soon.</motion.p> 
                            <motion.p variants={item} className="font-bold mb-[10px]"><a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> <br /> <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a></motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("New")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">I am NOT an established patient at CHAS Health for behavioral health.</button> 
                                <button onClick={()=>callbackOnClick("Established")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">I am an established patient at CHAS Health for behavioral health.</button>
                            </motion.div>
                        </div>
                    )}
                    
                    {urlParams[1] === "Other" && (<motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>)}
                    {urlParams[1] === "Other" && (<motion.p variants={item} className="font-bold italic">Please Call <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">WA 509-444-8200</a> or <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">ID 208-848-8300</a> for other service offerings.</motion.p>)}
                    {urlParams[1] === "Insurance Enrollment" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Insurance enrollment appointment self-scheduling is currently available for:</motion.p>
                            <motion.p variants={item} className="font-bold mb-[10px]">New or existing CHAS Health patients, or non-patients.</motion.p>
                            <motion.p variants={item} className="font-bold mb-[10px]">CHAS Health Patient Resources Coordinators (PRC) can assist with insurance enrollment and other resource needs. If you need help scheduling with a PRC please call us </motion.p> 
                            <motion.p variants={item} className="font-bold mb-[10px]"><a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> <br /> <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a></motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Continue</button>
                            </motion.div>    
                        </div>
                        
                    )}
                    {urlParams[1] === "School Based Health Clinics" && (
                        <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-xl sm:text-2xl font-bold mb-[20px]">Patient appointment self-scheduling is currently available for:</motion.p>
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">Students and Staff Members of North Central, Rogers, and Shadle Park High School</motion.p> 
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">Students, Staff, and Family Members of Shiloh Hills Elementary School</motion.p> 
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">Please call us for any other appointment needs at this time.</motion.p> 
                            <motion.p variants={item} className="font-bold mb-[10px]"><a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.502.6092">509-502-6092</a> </motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button> 
                             </motion.div>
                        
                        </div>
                    )}
                    </motion.div>;
            case 2:
                console.log('Case 2: urlParams:', urlParams);
                if(urlParams[1] === "Immunization" || urlParams[1] === "Insurance Enrollment"){
                    setTimeout(function(){
                        callbackOnClick("Continue");
                    }, 500)
                    
                    return;
                } else {
                    return (
                        <motion.div className="flex justify-center items-center flex-col">
                            {(urlParams[1] !== "Behavioral Health" && urlParams[1] !== "Urgent Care" &&urlParams[1] !== "School Based Health Clinics") &&(
                               <div className="max-w-[800px]">
                                    <motion.p variants={item} className="font-bold mb-[10px]">If you are currently taking more than 5 prescribed medications, <br />you may benefit from additional care team support.</motion.p>
                                    <motion.p variants={item} className="font-bold italic">Please call <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> or <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a> so that we can help you schedule an appointment that better meets your healthcare needs.</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Continue</button> 
                                        <a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>
                                    </motion.div>
                                </div>  
                            )}
                            {(urlParams[1] === "Behavioral Health" && urlParams[2] === "New") && (
                                <div className="max-w-[800px]">
                                    {/*<motion.p variants={item} className="font-bold mb-[10px]">New Patient - Schedule only at CBHC locations or schedule with PCP to initiate BH referral to services.</motion.p>*/}
                                    <motion.p variants={item} className="font-bold italic">If in need of a behavioral health referral, please call <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> or <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a> so that we can help you schedule an appointment that better meets your healthcare needs.</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        {/* TODO: update prop 1 to medical on button click */}
                                        {/*<button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Continue</button> */}
                                        <a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>
                                    </motion.div>
                                </div>
                            )}         
                            {(urlParams[1] === "Behavioral Health" && urlParams[2] === "Established") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="font-bold mb-[10px]">If you have not been seen for Behavioral Health services for more than 3 months, please call <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:509.444.8200">WA 509-444-8200</a> or <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300" href="tel:208.848.8300">ID 208-848-8300</a> so that we can help you schedule an appointment that better meets your healthcare needs. </motion.p>
                                    {/* <motion.p variants={item} className="font-bold italic"></motion.p> */}
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Continue if you have been seen for Behavioral Health services within the last 3 months.</button> 
                                        <a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>
                                    </motion.div>
                                </div>
                            )} 
                            {(urlParams[1] === "Urgent Care" && urlParams[2] === "Cold") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="font-bold mb-[10px]">Do you have shortness of breath or chest pain? </motion.p>
                                    {/* <motion.p variants={item} className="font-bold italic"></motion.p> */}
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Yes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button> 
                                        <button onClick={()=>callbackOnClick("No")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                    </motion.div>
                                </div>
                            )}
                            {(urlParams[1] === "Urgent Care" && urlParams[2] === "Sinusitis") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="font-bold mb-[10px]">Has this been going on for 2 or more weeks? </motion.p>
                                    {/* <motion.p variants={item} className="font-bold italic"></motion.p> */}
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Yes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button> 
                                        <button onClick={()=>callbackOnClick("No")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                    </motion.div>
                                </div>
                            )}
                            {(urlParams[1] === "Urgent Care" && urlParams[2] === "Diarrhea") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="font-bold mb-[10px]">Has this been ongoing for 1 week or more?</motion.p>
                                    {/* <motion.p variants={item} className="font-bold italic"></motion.p> */}
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Yes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button> 
                                        <button onClick={()=>callbackOnClick("No")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                    </motion.div>
                                </div>
                            )}
                            {(urlParams[1] === "Urgent Care" && urlParams[2] === "Medication Refills") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="font-bold mb-[10px]">Are you an established CHAS patient?</motion.p>
                                    {/* <motion.p variants={item} className="font-bold italic"></motion.p> */}
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Yes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button> 
                                        <button onClick={()=>callbackOnClick("No")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                    </motion.div>
                                </div>
                            )}
                            {(urlParams[1] === "Urgent Care" && urlParams[2] !== "Medication Refills"&& urlParams[2] !== "Diarrhea" && urlParams[2] !== "Sinusitis"  && urlParams[2] !== "Cold") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                    <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                    </motion.div>
                                    <motion.p variants={item} className="font-bold mb-[10px]">Currently, Spokane Urgent Care are only offered to patients who are physically located in Washington state at the time of their visit. Please visit either Spokane Urgent Care location in person, the last check-in time is 1 hour before closing.</motion.p>
                                    
                                </div>  
                            )}     
                            {(urlParams[1] === "School Based Health Clinics") && (
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>
                                    <motion.p variants={item} className="font-bold mb-[10px]">I want to schedule for: </motion.p>
                                    
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        { (<button onClick={()=>callbackOnClick("Behavorial Health")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Behavioral Health/Therapy</button>)}
                                        { (<button onClick={()=>callbackOnClick("Primary Care")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Primary Care</button>)} 
                                        { (<button onClick={()=>callbackOnClick("Immunization")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Immunization</button>)} 
                                    </motion.div>
                                    { (<motion.div variants={item} className="font-bold italic mt-2">Please call <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-502-6092</a> for confidential health visits including:
                                        <ul className="text-left list-disc w-[300px] mx-auto">
                                            <li>Birth Control Services/ Prenatal Care Services (12 and older)</li>
                                            <li>Behavioral Health/Therapy Visits (13 and older)</li>
                                            <li>Sexually Transmitted Diseases, Infections, Treatments, Counseling (14 and older)</li>
                                            
                                        </ul>
                                        </motion.div>)}
                                </div>  
                            )}         
                        </motion.div>
                    );
                }
            
            case 3: console.log('Case 3: urlParams', urlParams);               
            if(urlParams[1] === "Immunization")
            {
                return(
                    <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                            <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                <button onClick={()=>callbackOnClick("Idaho")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Idaho</button>
                            </motion.div>
                            </div>  
                )
                
            }

                else if(urlParams[1]==="Insurance Enrollment")
                {
                    return(
                        <div className="max-w-[800px]">
                                <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                                <motion.div variants={item} className="flex justify-center items-center flex-col">
                                    <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                    <button onClick={()=>callbackOnClick("Idaho")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Idaho</button>
                                </motion.div>
                                </div>  
                    )
                }
                else if(urlParams[1]==="School Based Health Clinics")
                    {
                        return <motion.div className="flex justify-center items-center flex-col">
                            <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have many convenient locations ready to serve you.</motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <div className="flex justify-center items-start gap-2 max-w-[500px]">
                                {locationRender(urlParams[1], urlParams[4])}                            
                                </div> 
                            </motion.div>
                            </motion.div>;
                    }

                
            else if(urlParams[1]==="Urgent Care" && urlParams[3] === "Washington")
                {
                    return <motion.div className="flex justify-center items-center flex-col">
                            {(
                                <div className="max-w-[800px]">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">How would you like to be seen for your urgent care visit?</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]"></motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                            <button onClick={()=>callbackOnClick("Video")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Video Visit</button>
                                            <button onClick={()=>callbackOnClick("In Person")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Reserve a spot in person</button>
                                        </motion.div>
                                    </div>  
                                )}  
                                
                        </motion.div>
                }
            else{
            return (
                    
                    <motion.div className="flex justify-center items-center flex-col">
                        {(urlParams[1] ==="Urgent Care" && urlParams[2] === "Medication Refills" && urlParams[3]==="Yes")&&(
                               <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Medication Refill</motion.p>
                                    <motion.p variants={item} className="font-bold mb-[10px]">Do you need 3 or more medications refilled?</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Yes")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button> 
                                        <button onClick={()=>callbackOnClick("No")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                    </motion.div>
                                    
                                </div>  
                        )}
                        {(urlParams[1] ==="Urgent Care" && urlParams[2] === "Medication Refills" && urlParams[3]==="No")&&(
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                                            <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                        </motion.div>
                                        <motion.p variants={item} className="font-bold mb-[10px]">Currently, Spokane Urgent Care are only offered to patients who are physically located in Washington state at the time of their visit. Please visit either Spokane Urgent Care location in person, the last check-in time is 1 hour before closing.</motion.p>
                                </div>   
                        )}
                        {(urlParams[1] ==="Urgent Care" && urlParams[2] !== "Medication Refills" &&urlParams[3] === "Yes")&&(
                                <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                                            <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                        </motion.div>
                                        <motion.p variants={item} className="font-bold mb-[10px]">Currently, Spokane Urgent Care are only offered to patients who are physically located in Washington state at the time of their visit. Please visit either Spokane Urgent Care location in person, the last check-in time is 1 hour before closing.</motion.p>
                                </div>  
                        )}
                        {(urlParams[1] ==="Urgent Care" && urlParams[2] !== "Medication Refills" &&urlParams[3] === "No")&&(
                               <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                                            <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                        </motion.div>
                                        <motion.p variants={item} className="font-bold mb-[10px]">Currently, Spokane Urgent Care are only offered to patients who are physically located in Washington state at the time of their visit. Please visit either Spokane Urgent Care location in person, the last check-in time is 1 hour before closing.</motion.p>
                                </div>  
                        )}    
                        {urlParams[1] !=="Urgent Care" &&(
                               <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                    <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of:</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                        <button onClick={()=>callbackOnClick("Idaho")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Idaho</button>
                                    </motion.div>
                                    
                                </div>  
                            )}  
                        
                       
                    </motion.div>
                );}
            case 4: console.log('Case 4: urlParams', urlParams);
                if(urlParams[1] === "Immunization" || urlParams[1]==="Insurance Enrollment"){
                    setTimeout(function(){
                        callbackOnClick("Continue");
                    }, 500)
                    
                    return;
                }
                
                else if(urlParams[1] === "School Based Health Clinics" )
                    return (
                        
                        <motion.div className="flex justify-center items-center flex-col">
                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                        <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                        <motion.div variants={item}>
                        <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                            <motion.li variants={item} className="font-bold italic text-sm">The patient wants to schedule at {urlParams[4]}</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[3]}.</motion.li>
                        </motion.ul>
                        </motion.div>                    
                        <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {(<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.502.6092">509-502-6092</a>)} to schedule your visit over the phone.</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                            {(<a href="tel:509.502.6092" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        </motion.div>
                        <motion.p variants={item} className="font-bold mt-[10px]">To book a confidential visit, please call {(<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.502.6092">509-502-6092</a>)}. When minors visit a provider at CHAS Health, there are certain services they may consent to without parental involvement depending on the patient’s age. These visits include: Birth control / Pregnancy care (12 and older), Mental Health therapy visits (13 and older), and Sexually Transmitted Disease diagnosis, treatment, counseling (14 and older)</motion.p>
                        </motion.div>
                    );
                
                
                else if(urlParams[1] === "Urgent Care" && urlParams[2] === "Medication Refills" && urlParams[4]==="No" )
                    return (
                        <motion.div className="flex justify-center items-center flex-col">
                            {urlParams[1] ==="Urgent Care" &&(
                                <div className="max-w-[800px]">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of: </motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                                            <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                        </motion.div>
                                        <motion.p variants={item} className="font-bold mb-[10px]">Currently, Spokane Urgent Care are only offered to patients who are physically located in Washington state at the time of their visit. Please visit either Spokane Urgent Care location in person, the last check-in time is 1 hour before closing.</motion.p>
                                    </div>  
                                )}  
                                
                            {urlParams[1] !=="Urgent Care" &&(
                                <div className="max-w-[800px]">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">State</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]">I want to be seen in the state of:</motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                                            <button onClick={()=>callbackOnClick("Washington")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Washington</button> 
                                            <button onClick={()=>callbackOnClick("Idaho")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Idaho</button>
                                        </motion.div>
                                        
                                    </div>  
                                )}  
                        
                        </motion.div>
                    );
                    else if(urlParams[1] === "Urgent Care" )
                    return (
                        <motion.div className="flex justify-center items-center flex-col">
                            {urlParams[1] ==="Urgent Care" && urlParams[2] === "Medication Refills" && (urlParams[4] === "Yes"||urlParams[3]==="No")&&(
                                <div className="max-w-[800px]">
                                <motion.p variants={item} className="text-2xl font-bold mb-[20px]">How would you like to be seen for your urgent care visit?</motion.p>
                                <motion.p variants={item} className="font-bold mb-[10px]"></motion.p>
                                <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                   <button onClick={()=>callbackOnClick("In Person")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Reserve a spot in person</button>
                                </motion.div>
                            </div>  
                                )}  
                                {urlParams[1] ==="Urgent Care" && (urlParams[2] === "Sinusitis" || urlParams[2] === "Cold" || urlParams[2] === "Diarrhea") && urlParams[3] === "No" && urlParams[4] === "Washington"&&(
                                
                                <div className="max-w-[800px]">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">How would you like to be seen for your urgent care visit?</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]"></motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                            <button onClick={()=>callbackOnClick("Video")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Video Visit</button>
                                            <button onClick={()=>callbackOnClick("In Person")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Reserve a spot in person</button>
                                        </motion.div>
                                    </div>  
                                
                                )} 
                                {urlParams[1] ==="Urgent Care" && (urlParams[2] === "Sinusitis" || urlParams[2] === "Cold" || urlParams[2] === "Diarrhea") && urlParams[3] === "Yes" && urlParams[4] === "Washington"&&(
                                
                                <div className="max-w-[800px]">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">How would you like to be seen for your urgent care visit?</motion.p>
                                        <motion.p variants={item} className="font-bold mb-[10px]"></motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                            
                                            <button onClick={()=>callbackOnClick("In Person")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Reserve a spot in person</button>
                                        </motion.div>
                                    </div>  
                                
                                )}
                                {urlParams[1] ==="Urgent Care" && !(urlParams[2] === "Sinusitis" || urlParams[2] === "Cold" || urlParams[2] === "Diarrhea") && urlParams[3] === "Washington"&& urlParams[4]==="Video"&&(
                                
                                <div className="max-w-[800px]">
                                        <motion.div className="flex justify-center items-center flex-col">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Have you been seen at CHAS or Spokane Urgent Care in the last 3 years?</motion.p>
                                        <button onClick={()=>callbackOnClick("Established")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button>
                                        <button onClick={()=>callbackOnClick("New")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                                    
                                        </motion.div>
                                    </div>  
                                
                                )}
                                {urlParams[4]==="In Person"&&
                                    (<div className="max-w-[800px]">
                                        <motion.div className="flex justify-center items-center flex-col">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Have you been seen at CHAS or Spokane Urgent Care in the last 3 years?</motion.p>
                                        <button onClick={()=>callbackOnClick("Established")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button>
                                        <button onClick={()=>callbackOnClick("New")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                                    
                                        </motion.div>
                                    </div>
                                )}
                                {urlParams[5]==="In Person"&&urlParams[1]==="Urgent Care"&&(
                                    <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have two convenient locations ready to serve you.</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <div className="flex justify-center items-start gap-2 max-w-[500px]">
                                        {locationRender(urlParams[1], urlParams[5])}                            
                                        </div> 
                                    </motion.div>
                                    </div>
                                )} 
                        </motion.div>
                    );
                
                else {
                return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        {(urlParams[1] === "Medical") && (<button onClick={()=>callbackOnClick("Adult/Self")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for an adult (18+)</button>)}
                        {urlParams[1] === "Dental" && (<button onClick={()=>callbackOnClick("Adult/Self")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for myself (18+)</button>)}
                        {(urlParams[1] === "Medical") && (<button onClick={()=>callbackOnClick("Child")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for a child (0-17 years old)</button>)} 
                        {urlParams[1] === "Dental" && (<button onClick={()=>callbackOnClick("Child")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for a child (0-12 years old)</button>)}
                        {urlParams[1] === "Dental" && (<button onClick={()=>callbackOnClick("Teen")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for a teen (12-17 years old)</button>)}
                        {(urlParams[1] === "Behavioral Health" ) && (<button onClick={()=>callbackOnClick("Adult/Self")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for an adult (18+) </button>)}
                        {(urlParams[1] === "Behavioral Health" ) && (<button onClick={()=>callbackOnClick("Child")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for a child (0-17 years old) </button>)}
                        {(urlParams[1] === "Urgent Care" ) && (<button onClick={()=>callbackOnClick("Adult/Self")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for an adult (18+) </button>)}
                        {(urlParams[1] === "Urgent Care" ) && (<button onClick={()=>callbackOnClick("Child")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Visit for a child (0-17 years old) </button>)} 

                    </motion.div>
                    
                    {(urlParams[1] === "Medical") && (<motion.div variants={item} className="font-bold italic mt-2">Please call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for specialty visits including:
                    <ul className="text-left list-disc w-[230px] mx-auto">
                        <li>Reproductive & Sexual Health</li>
                        <li>Women's Health</li>
                        <li>OB/Pregnancy Care</li>
                        <li>HIV/AIDS</li>
                        <li>Hepatitis C</li>
                    </ul>
                        </motion.div>)}

                    {(urlParams[1] === "Behavioral Health" ) && (<motion.div variants={item} className="font-bold italic mt-2">Please call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for specialty visits including:
                    <ul className="text-left list-disc w-[300px] mx-auto">
                        <li>CHAS Opioid Recovery Clinic</li>
                        <li>Substance Use Disorder Services</li>
                        <li>Peer Services</li>
                        <li>Behavioral Health Care Navigators</li>
                    </ul>
                    </motion.div>)}
                    {/* {urlParams[1] === "Behavioral Health" && (<motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>)} */}
                    {/* {urlParams[1] === "Behavioral Health" && (<motion.p variants={item} className="font-bold italic">Please Call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for Behavioral Health Services.</motion.p>)} */}
                    
                    {urlParams[1] === "Other" && (<motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>)}
                    {urlParams[1] === "Other" && (<motion.p variants={item} className="font-bold italic">Please Call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for other service offerings.</motion.p>)}
                    
                    </motion.div>;}
            case 5:
                console.log("Case 5: ", urlParams);
                if(urlParams[1] === "Behavioral Health" && urlParams[5] === "Child"){
                    return <motion.div className="flex justify-center items-center flex-col">
                        <motion.p variants={item} className="text-xl font-bold mb-[20px]">Confidential Visit - Child</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            {/* <div className="flex justify-center items-start gap-2 max-w-[500px]"> 
                            <motion.p variants={item} className="font-bold mb-[10px]">Press Continue to schedule a BH appointment or call the office to schedule a confidential phone call.</motion.p>  
                            <button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Continue</button>  
                            <motion.p variants={item} className="font-bold italic">Please Call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for other service offerings.</motion.p>                     
                            </div>  */}
                            <div className="max-w-[800px]">
                            <motion.p variants={item} className="text-sm sm:text-normal font-bold mb-[10px]">Press Continue to schedule an appointment or call the office to schedule a confidential phone call.</motion.p> 
                            <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                <button onClick={()=>callbackOnClick("Continue")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Continue</button>
                                <a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>
                            </motion.div>
                        </div>
                        </motion.div>                    
                    </motion.div>;
                } 
                else if(urlParams[1]==="Urgent Care" && urlParams[2]!=="Medication Refills"&& urlParams[3] === "Washington" && urlParams[4] === "Video")
                {
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[3]} state.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like a video visit.</motion.li>
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[5] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[5] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;
                }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]!=="Medication Refills"/*&& urlParams[3] === "No"*/ && urlParams[4] === "Washington")
                {
                    
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Have you been seen at CHAS or Spokane Urgent Care in the last 3 years?</motion.p>
                    <button onClick={()=>callbackOnClick("Established")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Yes</button>
                    <button onClick={()=>callbackOnClick("New")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">No</button>
                                
                                   
                
                    </motion.div>;
                }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]==="Medication Refills" && urlParams[4] === "No" && urlParams[5]==="Washington")
                {
                    return <motion.div className="flex justify-center items-center flex-col">
                            {(
                                <div className="max-w-[800px]">
                                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">How would you like to be seen for your urgent care visit?</motion.p>
                                        <motion.div variants={item} className="flex justify-center items-center flex-col mb-4">
                                            <button onClick={()=>callbackOnClick("Video")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Video Visit</button>
                                            <button onClick={()=>callbackOnClick("In Person")} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Reserve a spot in person</button>
                                            </motion.div>
                                    </div>  
                                )}  
                                
                        </motion.div>
                }
                else if(urlParams[1]==="Urgent Care" && urlParams[4] === "Washington" && urlParams[5]==="In Person")
                    {
                        return <motion.div className="flex justify-center items-center flex-col">
                            <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have two convenient locations ready to serve you.</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            <div className="flex justify-center items-start gap-2 max-w-[500px]">
                            {locationRender(urlParams[1], urlParams[5])}                            
                            </div> 
                        </motion.div></motion.div>

                    }
                    else if(urlParams[1]==="Urgent Care" && urlParams[2] === "Medication Refills" && urlParams[4] === "Yes" && urlParams[5]==="In Person")
                        {
                            return <motion.div className="flex justify-center items-center flex-col">
                                <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have two convenient locations ready to serve you.</motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <div className="flex justify-center items-start gap-2 max-w-[500px]">
                                {locationRender(urlParams[1], urlParams[5])}                            
                                </div> 
                            </motion.div></motion.div>
    
                        }
                else if(urlParams[1]==="Urgent Care" && urlParams[3]==="Washington" && urlParams[4] === "In Person")
                {
                    /*return <motion.div className="flex justify-center items-center flex-col">
                            <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                            <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                            <motion.div variants={item}>
                            <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                                <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[3]} state.</motion.li>
                                <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                                <motion.li variants={item} className="font-bold italic text-sm">The patient would like a {urlParams[4]} visit at {urlParams[5]}.</motion.li>
                            </motion.ul>
                            </motion.div>                    
                            <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[5] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                                {urlParams[5] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                            </motion.div>
                            </motion.div>*/
                            return <div className="max-w-[800px]">
                                    <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have two convenient locations ready to serve you.</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <div className="flex justify-center items-start gap-2 max-w-[500px]">
                                        {locationRender(urlParams[1], urlParams[4])}                            
                                        </div> 
                                    </motion.div>
                                    </div>
                }
               
                else {
                    return <motion.div className="flex justify-center items-center flex-col">
                    {urlParams[1] === "Other" && (<motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>)}
                    <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have many convenient locations ready to serve you.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <div className="flex justify-center items-start gap-2 max-w-[500px]">
                        {locationRender(urlParams[1], urlParams[4])}                            
                        </div> 
                    </motion.div>
                    {urlParams[2] === "Established" && urlParams[1] !== "Immunization" && (<motion.p variants={item} className="font-bold italic">At CHAS Health, we value our patient care team relationships. Please choose your home clinic for your visit. If you are unsure who to schedule with or need any help please call us.<br /> {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)}</motion.p>)} 
                    </motion.div>;
                    
                }
                
            case 6:
                console.log("Case 6:",urlParams);
                 
                if(urlParams[1] === "Behavioral Health" && urlParams[5] === "Child"){
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">{urlParams[1]}</motion.p>
                    <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have many convenient locations ready to serve you.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <div className="flex justify-center items-start gap-2 max-w-[500px]">
                        {locationRender(urlParams[1], urlParams[4])}                            
                        </div> 
                    </motion.div>
                    </motion.div>;
                } 
                else if(urlParams[1]==="Urgent Care" && urlParams[3] === "Washington" && urlParams[4] === "In Person")
                    {
                        return <motion.div className="flex justify-center items-center flex-col">
                            <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                            <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                            <motion.div variants={item}>
                            <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                                <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[3]} state.</motion.li>
                                <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                                <motion.li variants={item} className="font-bold italic text-sm">The patient would like a {urlParams[4]} visit at {urlParams[6]}.</motion.li>
                            </motion.ul>
                            </motion.div>                    
                            <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[3] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                                {urlParams[3] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                            </motion.div>
                            </motion.div>
                    }
                    else if(urlParams[1]==="Urgent Care" && urlParams[5] === "In Person" && urlParams[4]==="Washington" && (urlParams[6]==="New"||urlParams[6]==="Established"))
                        {
                            return <motion.div className="flex justify-center items-center flex-col">
                                <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have two convenient locations ready to serve you.</motion.p>
                            <motion.div variants={item} className="flex justify-center items-center flex-col">
                                <div className="flex justify-center items-start gap-2 max-w-[500px]">
                                {locationRender(urlParams[1], urlParams[5])}                            
                                </div> 
                            </motion.div></motion.div>
    
                        }
                    else if(urlParams[1]==="Urgent Care" && urlParams[4] === "Yes" && urlParams[5] === "In Person")
                        {
                            return <motion.div className="flex justify-center items-center flex-col">
                                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                                    <motion.div variants={item}>
                                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in Washington state.</motion.li>
                                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like a {urlParams[5]} visit at {urlParams[6]}.</motion.li>
                                    </motion.ul>
                                    </motion.div>                    
                                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call <a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a> to schedule your visit over the phone.</motion.p>
                                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                                        </motion.div>
                                    </motion.div>
                        }
                        else if(urlParams[1]==="Urgent Care" && urlParams[2] === "Medication Refills" && urlParams[6] === "In Person" && urlParams[5]==="Washington")
                            {
                                return <motion.div className="flex justify-center items-center flex-col">
                                    <motion.p variants={item} className="text-xl font-bold mb-[20px]">We have two convenient locations ready to serve you.</motion.p>
                                <motion.div variants={item} className="flex justify-center items-center flex-col">
                                    <div className="flex justify-center items-start gap-2 max-w-[500px]">
                                    {locationRender(urlParams[1], urlParams[6])}                            
                                    </div> 
                                </motion.div></motion.div>
        
                            }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]!=="Medication Refills"&& urlParams[3] === "No" && urlParams[4] === "Washington")
                {
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like a video visit.</motion.li>
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[5] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[5] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;
                }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]!=="Medication Refills"&& urlParams[3] === "Yes" && urlParams[4] === "Washington" && urlParams[5]==="In Person")
                    {
                        console.log(
                            "URL Param 1 - " + urlParams[1],
                            "URL Param 2 - " + urlParams[2],
                            "URL Param 3 - " + urlParams[3],
                            "URL Param 4 - " + urlParams[4],
                            "URL Param 5 - " + urlParams[5],
                            "URL Param 6 - " + urlParams[6],
                            "URL Param 7 - " + urlParams[7],
                            "URL Param 8 - " + urlParams[8],
                        )
                        return <motion.div className="flex justify-center items-center flex-col">
                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                        <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                        <motion.div variants={item}>
                        <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient would like a {urlParams[5]} visit at {urlParams[6]}.</motion.li>
                        </motion.ul>
                        </motion.div>                    
                        <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                            {urlParams[4] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        </motion.div>
                        </motion.div>;
                    }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]==="Medication Refills" && urlParams[3] === "No" && urlParams[4]==="Washington" && urlParams[5]==="In Person")
                    {
                        console.log(
                            "URL Param 1 - " + urlParams[1],
                            "URL Param 2 - " + urlParams[2],
                            "URL Param 3 - " + urlParams[3],
                            "URL Param 4 - " + urlParams[4],
                            "URL Param 5 - " + urlParams[5],
                            "URL Param 6 - " + urlParams[6],
                            "URL Param 7 - " + urlParams[7],
                            "URL Param 8 - " + urlParams[8],
                        )
                        return <motion.div className="flex justify-center items-center flex-col">
                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                        <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                        <motion.div variants={item}>
                        <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient would like an In Person visit at {urlParams[6]}.</motion.li>
                        </motion.ul>
                        </motion.div>                    
                        <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                            {urlParams[4] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        </motion.div>
                        </motion.div>;
                    }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]==="Medication Refills" && urlParams[4] === "No" && urlParams[5]==="Washington" && urlParams[6]==="Video")
                {
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[5]} state.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like a video visit.</motion.li>
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[5] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[5] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;
                }
                
                else {
                    if(urlParams[1] === "Immunization"){
                        setTimeout(function(){
                            callbackOnClick("Continue");
                        }, 500)
                        
                        return;
                    } else if(urlParams[5] === "Child") {
                        setTimeout(function(){
                            callbackOnClick("In Person");
                        }, 500);
                        return;
                    } else {
                        //console.log(urlParams);
                    return <motion.div className="flex justify-center items-center flex-col">

                        {urlParams[1] === "Insurance Enrollment" && (
                            <>
                            <motion.p variants={item} className="font-bold italic mb-[20px]">Please select the type of appointment you would like to schedule.<br/> Many visits can be handled virtually, but we may still recommend you come in to better meet your needs.</motion.p> 
                            <button onClick={()=>callbackOnClick("In Person")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">In Person</button>
                            <button onClick={()=>callbackOnClick("Virtual")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">As a Video Visit</button>
                            <a href="tel:509.444.8200" className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>
                            </>
                            
                        )}
                        {urlParams[1] === "Medical" && urlParams[5] === "Child" &&(<motion.p className="font-bold italic mb-2">Currently, only well child and immunization visits are enabled for self-scheduling pediatric visits. To schedule another type of in-person office visit please call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)}.</motion.p>)}

                        {urlParams[1] === "Medical" && urlParams[5] !== "Child" && (<motion.p className="font-bold italic mb-2">Many visits can be handled virtually, but there are limitations. At a virtual visit we can discuss medical concerns, prescribe medications, and complete a limited exam on video. We may still recommend you come in for care later to better meet your needs.</motion.p>)}
                        
                        {urlParams[1] === "Behavioral Health" && urlParams[5] === "Child" &&(<motion.p className="font-bold italic mb-2">Currently, only well child and immunization visits are enabled for self-scheduling pediatric visits. To schedule another type of in-person office visit please call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)}.</motion.p>)}
                        {urlParams[1] === "Behavioral Health" && urlParams[5] !== "Child" && (<motion.p className="font-bold italic mb-2">Many visits can be handled virtually, but there are limitations. We may still recommend you come in for care later to better meet your needs.</motion.p>)}
                        
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                    
                            {urlParams[2] === "New" && (<button onClick={()=>callbackOnClick("In Person")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">In Person</button>)}

                            {urlParams[2] === "New" && (urlParams[1] === "Medical") && urlParams[5] !== "Child" && (<button onClick={()=>callbackOnClick("Virtual")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">As a Video Visit</button>)}

                            {urlParams[2] === "Established" && urlParams[5] === "Child" && (<button onClick={()=>callbackOnClick("In Person")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">In Person</button>)}

                            {urlParams[2] === "Established" && urlParams[6] !== "Behavioral Health Center Lewiston" && (urlParams[1] === "Medical" || urlParams[1] === "Behavioral Health") && urlParams[5] !== "Child" && (<button onClick={()=>callbackOnClick("In Person")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">In Person</button>)} 

                            {urlParams[2] === "Established" && (urlParams[1] === "Medical" || urlParams[1] === "Behavioral Health") && urlParams[5] !== "Child" && (<button onClick={()=>callbackOnClick("Virtual")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">As a Video Visit</button>)} 

                            {urlParams[2] === "Established" && urlParams[1] !== "Behavioral Health" && (<a href="tel:509.444.8200" className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)} 

                        </motion.div>
                        {urlParams[2] === "New" && (<motion.p variants={item} className="font-bold italic">Please call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for phone visits.</motion.p>)} 
                        
                        </motion.div>;}
                }
            case 7:
                console.log("Case 7:",urlParams);
                if(urlParams[1] === "Behavioral Health" && urlParams[5] === "Child"){
                    return <motion.div className="flex justify-center items-center flex-col">

                        {urlParams[1] === "Behavioral Health" && urlParams[5] === "Child" &&(<motion.p className="font-bold italic mb-2">Please select a visit type or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)}.</motion.p>)}                        
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            {urlParams[7] !== "Behavioral Health Center Lewiston" && (<button onClick={()=>callbackOnClick("In Person")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">In Person</button>)}
                            <button onClick={()=>callbackOnClick("Virtual")} className="p-4 m-2 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">As a Video Visit</button>
                        </motion.div>
                        {urlParams[2] === "New" && (<motion.p variants={item} className="font-bold italic">Please call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} for phone visits.</motion.p>)} 
                        </motion.div>;
                } 
                else if(urlParams[1]==="Urgent Care" && urlParams[4] === "Washington" && urlParams[5] === "In Person" && (urlParams[6]==="New" || urlParams[6]==="Established"))
                    {
                        return <motion.div className="flex justify-center items-center flex-col">
                                <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                                <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                                <motion.div variants={item}>
                                <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                                    <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                                    <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                                    <motion.li variants={item} className="font-bold italic text-sm">The patient would like a {urlParams[5]} visit at {urlParams[7]}.</motion.li>
                                </motion.ul>
                                </motion.div>                    
                                <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                                <motion.div variants={item} className="flex justify-center items-center flex-col">
                                    <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                                    {urlParams[4] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                                </motion.div>
                                </motion.div>
                    }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]==="Medication Refills" && urlParams[4] === "No" && urlParams[5]==="Washington" && urlParams[6]==="Video")
                {
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[5]} state.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like a video visit.</motion.li>
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[5] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[5] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;
                }
                else if(urlParams[1]==="Urgent Care" && urlParams[2]==="Medication Refills" && urlParams[4] === "No" && urlParams[5]==="Washington" && urlParams[6]==="In Person")
                    {
                        console.log(
                            "URL Param 1 - " + urlParams[1],
                            "URL Param 2 - " + urlParams[2],
                            "URL Param 3 - " + urlParams[3],
                            "URL Param 4 - " + urlParams[4],
                            "URL Param 5 - " + urlParams[5],
                            "URL Param 6 - " + urlParams[6],
                            "URL Param 7 - " + urlParams[7],
                            "URL Param 8 - " + urlParams[8],
                        )
                        return <motion.div className="flex justify-center items-center flex-col">
                        <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                        <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                        <motion.div variants={item}>
                        <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[5]} state.</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking care for {urlParams[2]}.</motion.li>
                            <motion.li variants={item} className="font-bold italic text-sm">The patient would like an In Person visit.</motion.li>
                        </motion.ul>
                        </motion.div>                    
                        <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[5] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)} to schedule your visit over the phone.</motion.p>
                        <motion.div variants={item} className="flex justify-center items-center flex-col">
                            <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                            {urlParams[5] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        </motion.div>
                        </motion.div>;
                    }
                    else {
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        {urlParams[1] !== "Insurance Enrollment" && (<motion.li variants={item} className="font-bold italic text-sm">The patient is {urlParams[2]==="New"&&("a New")} {urlParams[2]==="Established"&&("an established")} patient.</motion.li>)}
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                        {urlParams[1] !== "Insurance Enrollment" &&(<motion.li variants={item} className="font-bold italic text-sm">The patient is seeking {urlParams[1]} care.</motion.li>)}
                        {urlParams[1] === "Insurance Enrollment" &&(<motion.li variants={item} className="font-bold italic text-sm">The patient is seeking {urlParams[1]}.</motion.li>)}
                        {urlParams[1] !== "Immunization" && urlParams[1] !== "Insurance Enrollment" && (<motion.li variants={item} className="font-bold italic text-sm">The patient is {urlParams[5]==="Adult/Self"&&("an adult")}{urlParams[5]==="Child"&&("a child")}.</motion.li>)}
                        {urlParams[1] !== "Immunization" && (<motion.li variants={item} className="font-bold italic text-sm">The patient would like to schedule at {urlParams[6]}.</motion.li>)}
                        {urlParams[1] !== "Immunization" && (<motion.li variants={item} className="font-bold italic text-sm">The patient would like {urlParams[7]==="In Person"&&("an in person")}{urlParams[7]==="Virtual"&&("a video")} visit.</motion.li>)}
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[4] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        {urlParams[4] === "Idaho" && (<a href="tel:208.484.8300" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;
                }
            case 8:
                if(urlParams[1] === "Behavioral Health" && urlParams[5] === "Child"){
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is {urlParams[2]==="New"&&("a New")} {urlParams[2]==="Established"&&("an established")} patient.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking {urlParams[1]} care.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is {urlParams[5]==="Child"&&("a child")}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like to schedule at {urlParams[7]}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like {urlParams[8]==="In Person"&&("an in person")}{urlParams[8]==="Virtual"&&("a video")} visit.</motion.li>
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[4] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        {urlParams[4] === "Idaho" && (<a href="tel:208.484.8300" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;
                } 
                else if (urlParams[1] === "Urgent Care"){
                    console.log(
                        "URL Param 1 - " + urlParams[1],
                        "URL Param 2 - " + urlParams[2],
                        "URL Param 3 - " + urlParams[3],
                        "URL Param 4 - " + urlParams[4],
                        "URL Param 5 - " + urlParams[5],
                        "URL Param 6 - " + urlParams[6],
                        "URL Param 7 - " + urlParams[7],
                        "URL Param 8 - " + urlParams[8],
                    )
                    return <motion.div className="flex justify-center items-center flex-col">
                    <motion.p variants={item} className="text-2xl font-bold mb-[20px]">Summary </motion.p>
                    <motion.p variants={item}  className="font-bold mb-[10px]">Great! Let's review your selections. <br/> You have selected:</motion.p>
                    <motion.div variants={item}>
                    <motion.ul variants={item} className="text-left list-disc w-[230px] sm:w-[300px] mx-auto">
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is in {urlParams[4]} state.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is seeking {urlParams[1]} care.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient is {urlParams[5]==="Child"&&("a child")}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like to schedule at {urlParams[7]}.</motion.li>
                        <motion.li variants={item} className="font-bold italic text-sm">The patient would like video visit.</motion.li>
                    </motion.ul>
                    </motion.div>                    
                    <motion.p variants={item} className="font-bold mt-[10px]">If this all looks correct please select "Continue" below.  If this is not correct you may use the "previous" button to make corrections or call {urlParams[4] === "Washington" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:509.444.8200">509-444-8200</a>)}{urlParams[4] === "Idaho" && (<a className="text-chas-green font-bold drop-shadow-md hover:text-lg ease-in-out duration-300 basis-0 grow" href="tel:208.848.8300">208-848-8300</a>)} to schedule your visit over the phone.</motion.p>
                    <motion.div variants={item} className="flex justify-center items-center flex-col">
                        <button onClick={()=>buildAndRedirect()} className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300 basis-0 grow w-full">Continue</button>
                        {urlParams[4] === "Washington" && (<a href="tel:509.444.8200" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                        {urlParams[4] === "Idaho" && (<a href="tel:208.484.8300" className="p-2 m-1 bg-chas-green text-white font-bold hover:scale-110 hover:bg-chas-highlight ease-in-out duration-300  basis-0 grow w-full">Call</a>)}
                    </motion.div>
                    </motion.div>;

                }
        
                else{                
                    return(
                        <motion.div className="flex justify-center items-center flex-col">
                        </motion.div>
                    )
                }
            default:
                return <div></div>;
        }
      }

      

      /** returning the jsx container which looks like a white card with animation (desktop) */
    return (
        <AnimatePresence>
            <motion.div 
                key={index} 
                initial="hidden"
                animate="visible"
                variants={list}
                exit={{ x:-250, opacity: 0 }} 
                className="flex flex-col h-auto w-full justify-center items-center text-center">
                <div className="card flex flex-col max-w-[1080px] h-3/4 w-full md:w-3/4 justify-center items-center drop-shadow-none sm:drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] bg-white/20 sm:bg-white p-6">
                    {stepContentRender(index)}
                </div>
                
                
            </motion.div>
        </AnimatePresence>
    );
  }
  
  export default StepForm;